.fw-600 {
  font-weight: 600
}

.fw-700 {
  font-weight: 700
}

.fw-800 {
  font-weight: 800
}



.fs-12 {
  font-size: 12px;
  line-height: 16px
}

.fs-14 {
  font-size: 14px;
  line-height: 16px
}

.fs-16 {
  font-size: 16px;
}


.fs-18 {
  font-size: 18px
}

.fs-20 {
  font-size: 20px
}

.fs-25 {
  font-size: 25px
}

.fs-25 {
  font-size: 25px
}

.fs-30 {
  font-size: 30px
}

.fs-40 {
  font-size: 40px
}

.fs-70 {
  font-size: 70px;
  line-height: 65px
}

.fs-100 {
  font-size: 100px;
  line-height: 90px
}


.fw-500 {
  font-weight: 500
}

.fw-600 {
  font-weight: 600
}
