.card {
  border: none;
}
.card-header {
  border-bottom: 4px solid #063571 !important
}

.wrapper-checkout {
    padding: 40px 60px;
    background-color: #fff;
}

.wrapper-checkout-top{
  border-top: 10px solid #063571;
}
