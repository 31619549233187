.top-0 {
  top:0
}
.right-0 {
  right:0
}
.left-0 {
  left:0
}
.bottom-0 {
  bottom:0
}

.position-fixed {
  position: fixed;
}

.top-50x {
  top: 50%
}
.right-5x {
  right: 5%
}
