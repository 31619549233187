.modal-fullscreen.modal {
  padding: 0 !important
}


.modal-desk.modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-desk .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
