@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9p1cut');
  src:  url('../fonts/icomoon.eot?9p1cut#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9p1cut') format('truetype'),
    url('../fonts/icomoon.woff?9p1cut') format('woff'),
    url('../fonts/icomoon.svg?9p1cut#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart-selected:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-freccia-dx:before {
  content: "\e901";
  color: #fff;
}
.icon-freccia-sx:before {
  content: "\e902";
  color: #fff;
}
.icon-heart:before {
  content: "\e905";
}
.icon-Instagram:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-select:before {
  content: "\e908";
  color: #063571;
}
.icon-user:before {
  content: "\e909";
}
.icon-youtube:before {
  content: "\e90a";
}
