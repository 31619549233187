.btn-one, .btn-one:hover, .btn-one:focus  {
  color: #fff;
  background-color: #063571;
  padding: 10px 20px;
  border-radius: 25px;
  text-transform: uppercase;
  border: none;
  cursor: pointer

}

.btn-two, .btn-two:hover, .btn-two:focus  {
  color: #fff;
  font-size: 15px;
  background-color: #063571;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  border: none;
  cursor: pointer

}

.button-search{
  background-color: transparent;
  border: none
}

.button-close {
  top: 5%;
  right: 5%;
  z-index: 2
}

.btn-checkout {
  border: 2px solid #063571;
}

.border-radius-25 {
  border-radius: 25px
}
