/* PADDING */
.pt-5px{
  padding-top: 5px
}

.pb-5px{
  padding-bottom: 5px
}

.p-10{
  padding: 10px
}

.pt-10 {
  padding-top: 10px
}

.pb-10 {
  padding-bottom: 10px
}

.pl-10 {
  padding-left: 10px
}

.pt-12 {
  padding-top: 12px
}

.pb-12 {
  padding-bottom: 12px
}

.pt-15 {
  padding-top: 15px
}

.pl-15 {
  padding-left: 15px
}


.pb-15 {
  padding-bottom: 15px
}

.pt-20 {
  padding-top: 20px
}

.pb-20 {
  padding-bottom: 20px
}
.pr-20 {
  padding-right: 20px
}

.pl-20{
  padding-left: 20px
}

.pr-20 {
  padding-right: 20px
}


.pr-10 {
  padding-right: 10px
}

.pr-30 {
  padding-right: 30px
}

.pl-30 {
  padding-left: 30px
}

.pl-40 {
  padding-left: 40px
}

.pt-50 {
  padding-top: 50px
}

.pb-50 {
  padding-bottom: 50px !important
}

.pl-50 {
  padding-left: 50px !important
}

.pr-50 {
  padding-right: 50px !important
}

.pl-80 {
  padding-left: 80px
}

.pr-80 {
  padding-right: 80px
}


.pt-100 {
  padding-top: 100px
}

.pl-100 {
  padding-left: 100px
}

.pt-150 {
  padding-top: 150px
}

.pb-100 {
  padding-bottom: 100px
}

.pt-200 {
  padding-top: 200px
}

.pb-200 {
  padding-bottom: 200px
}

/* MARGIN */
.ml-mr-auto {
  margin-left: auto;
  margin-right: auto;

}
.mt-sub-4 {
  margin-top: -4px
}

.mr-5px {
  margin-right: 5px
}

.mt-20 {
  margin-top: 20px
}


.mr-40 {
  margin-right: 40px
}


.mt-50 {
  margin-top: 50px
}

.mb-50 {
  margin-bottom: 50px
}

.mt-100 {
  margin-top: 100px
}

.mt-119 {
  margin-top: 119px
}

.mt-80 {
  margin-top: 80px
}

.mt-85 {
  margin-top: 85px
}

.mt-88 {
  margin-top: 88px
}

.mt-150 {
  margin-top: 150px
}
.mt-200 {
  margin-top: 200px
}

.mb-200 {
  margin-bottom: 200px
}

.mb-100 {
  margin-bottom: 100px
}

.mt-400 {
  margin-top: 400px
}