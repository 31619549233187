@media screen and (max-width: 1680px) {
  .card-product {
    width: 85%
  }

}

@media screen and (max-width: 1440px) {

  .col-mmd-100 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .justify-mmd-content-start {
    justify-content: flex-start !important;
  }

  .pb-md-50 {
    padding-bottom: 50px
  }

}




@media screen and (max-width: 1360px) {
  .display-mmd-none {
    display: none !important;
  }
  .display-mmd-block {
    display: block !important
  }
  .col-md-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .justify-md-center {
    justify-content: center
  }
}



@media (min-width: 1200px) {
    .container{
        max-width: 1400px;
    }

    .container-large {
      max-width: 1800px
    }
    .container-mid {
      max-width: 1600px;
    }

    .container-small {
      max-width: 1000px;
    }



}

.wrapper-main-footer {
    border-bottom: 2px solid #063571;
    border-bottom-color: rgb(6, 53, 113);
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

@media (max-width: 1200px) {
  .card-product {
      width: 100%;
  }
}


@media screen and (max-width: 1100px) {
    .col-sm-40 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

}



@media screen and (max-width: 1024px) {
  .padding-l-md-0  {
    padding-left: 0
  }
  .display-md-none {
    display: none !important
  }
  .col-md-100 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .w-md-100 {
    width: 100% !important
  }
  .wrapper-social {
    flex-direction: row;
    top: 14%;
  }
  .wrapper-social a {
    margin-right: 10px
  }
  .pt-md-50 {
    padding-top: 50px
    }
  .pb-md-50 {
    padding-bottom: 50px
  }
  .display-md-block {
    display: block !important
  }
  .col-sm-40 {
    -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
      flex: 0 0 40%;
      max-width: 40%;
  }
  .fs-sm-50 {
    font-size: 50px
  }
 .col-sm-100 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mt-md-20 {
    margin-top: 20px
  }

  .mt-md-0px {
    margin-top: 0px
  }

  .wrapper-cart-summary {
    max-height: 100%;
  }
  .position-sml-relative {
    position: relative !important
  }
  .text-sml-center {
    text-align: center;
  }
 }

 @media screen and (max-width: 1000px) {
   .display-mob-block {
     display: block !important
   }
   .display-mob-none {
     display: none !important
   }
   .logo-virgilio {
     height: 100%;
     left: 0;
     right: 0;
     display: block;
     margin: 0 auto;
   }

   .wrapper-title-home {
     display: block;
     margin: 0 auto;
     left: 0;
     right: 0;
     text-align: center;
   }

  }



@media screen and (max-width: 800px) {
  .padding-l-sm-0 {
    padding-left: 0
  }

  .text-sml-center {
    text-align: center;
  }

  .w-sm-100 {
    width: 100% !important
  }

  .text-sm-center {
    text-align: center;
  }

  .display-sm-none {
    display: none
  }
  .card-product {
    width: 100%
  }
  .col-xs-50 {
    -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
    .mb-sm-20 {
      margin-bottom: 20px
    }

    .gallery-home-products .swiper-button-prev,
    .gallery-home-products .swiper-button-next {
      top: auto;
      bottom: 5%
    }

    .gallery-home-products .swiper-button-next::after {
      content: "\f061";
      font-family: "Font Awesome 5 Free";
    }
    .gallery-home-products .swiper-button-prev::after {
      content: "\f060";
      font-family: "Font Awesome 5 Free";
    }
    .gallery-home-products .swiper-button-prev {
      left: auto;
      right: 20%
    }
    .mt-sm-50 {
      margin-top: 50px
    }

    .mt-sm-20 {
      margin-top: 20px
    }

    .mr-sm-20 {
      margin-right: 20px
    }
    .col-xs-100 {
      -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .wrapper-products-nav .nav-tabs .nav-link.active, .wrapper-products-nav .nav-tabs .nav-link {
      padding:  25px
    }

    .fs-sm-40 {
      font-size: 40px
    }

    .fs-sm-18 {
      font-size: 18px
    }

    .wrapper-checkout {
      padding: 10px 0
    }

    .col-sm-10x {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 10%;
      flex: 0 0 10%;
      max-width: 10%;
    }
    .col-sm-90 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 90%;
      flex: 0 0 90%;
      max-width: 90%;
  }

  .col-sm-60 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-sm-40 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

.gallery-product .swiper-button-prev,
.gallery-product .swiper-button-next {
  top: auto;
  bottom: 2%
}

.gallery-product .swiper-button-prev {
  right: 15%;
  left: auto;
}

.gallery-product .swiper-button-next::after, .gallery-product .swiper-button-prev::after {
  font-size: 25px !important
}

  .card-header:first-child {
    padding: 0 10px;
  }

  .round-number {
    width: 35px;
    height: 35px
  }

  .wrapper-checkout .checkout-data {
    order:2;
    padding: 0 !important;

  }

  .wrapper-checkout .checkout-resume {
    order:1;
    padding: 0 !important;
    margin-bottom: 50px
  }
  .mt-sm-10 {
    margin-top: 10px !important
  }

  .wrapper-checkout-top {
    margin-bottom: 10px
  }

  .card-body {
    padding: 0
  }

  .h-sm-auto {
    height: auto;
  }
  .row-description {
    order: 2
  }
  .row-image {
    order: 1
  }

  .pl-sm-15 {
    padding-left: 15px !important
  }

  .pr-sm-15 {
    padding-left: 15px !important
  }

  .pb-sm-50 {
    padding-bottom: 50px
  }
  .wrapper-fast-cart {
    width: 100%;
  }
  .fast-cart--products {
    height:auto !important;
  }
  .w-sm-50 {
    width: 50%
  }

  .col-sml-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sml-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .justify-sml-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between;
  }
  .modal {
    overflow-y:auto;
  }

  .col-sml-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;;

  }

  .col-sml-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

}

@media screen and (max-width: 750px) {
  .mt-sm-50 {
    margin-top: 50px
  }

  .pl-sm-0px {
    padding-left: 0 !important
  }
  .display-sm-block {
    display: block !important
  }

  .display-sm-none {
    display: none !important
  }

  .order-card {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;

  }

  .order-card .wrapper-track {
    order:1
  }

  .order-card .wrapper-article {
    order:2;
    text-align: center;
    margin-bottom: 20px;
    }

  .order-card .wrapper-price {
    order:3;
    align-items: center !important;
  }

  .order-card .wrapper-price p {
    margin-bottom: 20px
  }
  .dashboard-nav .nav-tabs .nav-link {
    padding: 5px;
    font-size: 14px
  }

  .pl-sm-0px {
    padding-left:0
  }
  .pr-sm-0px {
    padding-right:0
  }
}


@media screen and (max-width: 600px) {

}
