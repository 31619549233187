@import 'reset.css';
@import 'spacing.css';
@import 'sizing.css';
@import 'modals.css';
@import 'positioning.css';
@import 'colors.css';
@import 'cart.css';
@import 'buttons.css';
@import 'galleries.css';
@import 'checkout.css';
@import 'forms.css';


@font-face{
    font-family:"MeethlakeTwo";
    src:url("../fonts/MeethlakeTwo/font.woff2") format("woff2"),
    url("../fonts/MeethlakeTwo/font.woff") format("woff");
}



body, html, .ff-primary {
  font-family: roboto,sans-serif;
  font-weight: 400;
  font-style: normal;
}

img {
  max-width: 100%
}

h1, h2, h3, h4, h5, h6 {
  font-family:"MeethlakeTwo";
 }


p {
  font-size: 18px;
  line-height: 26px
}

.text-underine {
  text-decoration: underline !important
}

.w-100 {
  width: 100% !important
}

.w-90 {
  width: 90% !important
}

.w-80 {
  width: 80%
}

.w-70 {
  width: 70%
}

.w-60 {
  width: 60%
}

.h-25px {
  height: 25px
}

.h-156px {
  height: 156px
}

.min-h-120 {
  min-height: 120px
}

.height-650 {
  height: 650px
}
.center-grid {
  display: grid;
  place-items: center
}
.outline {
  outline: 1px solid red
}

.text-underline {
  text-decoration: underline !important
}

.cursor-pointer {
  cursor: pointer;
}

.z-index-2 {
  z-index: 2
}

.z-index-3 {
  z-index: 3
}

.z-index-15 {
  z-index: 15
}

.z-index-20 {
  z-index: 20
}


.z-index-30 {
  z-index: 30
}


.large-container {
  width: 100%;
  padding: 0 40px;
}

.opacity-5 {
  opacity: .5
}

.opacity-2 {
  opacity: .2
}


.logo {
  width: 100px
}

.logo-mob {
  width: 50px
}

.wrapper-top-access {
  min-width: 400px
}


 .search-menu input, .search-menu input:focus{
  background: transparent;
  border: none;
  color: #000;
  border-radius: 0;
  border-bottom: 1px solid #000;
  padding: 0;
  margin: 0;
  font-size: 12px
}
.search-menu input::placeholder {
  color: #000;
  font-size: 12px
}

.search-menu .form-group {
  margin: 0
}


.wrapper-breadcrumb * {
  font-size: 14px;
}
.wrapper-breadcrumb a::after {
  content: '/';
  margin: 0 10px
}

.product-title {
  font-size: 42px
}

.product-price {
  font-size: 35px;
  font-weight: 600;
}

.bg-size-cover {
  background-size: cover;
}

.bg-position-center {
  background-position: center;
}

.bg-norepeat {
  background-repeat: no-repeat;
}

.wrapper-qta-control span {
  cursor: pointer;
  text-align: center;
  width: 23px;
  height: 23px;
  display: grid;
  place-items:center;
  background-color: #CEE1F1
}

.wrapper-qta-control input, .wrapper-qta-list input{
  width: 30px;
  background-color: transparent;
  border-top: 2px solid #CEE1F1;
  border-bottom: 2px solid #CEE1F1;
  border-left: none;
  border-right: none;
  padding: 0;
  font-size: 14px

}

.wrapper-qta-list input {
  border: none
}

.wrapper-qta-list input {
  width: 20px;
}

.wrapper-qta-list span {
  cursor: pointer;
}

.wrapper-social {
  right: 5%;
  top: 35%;
  flex-direction: column;

}

.wrapper-composition .nav-tabs .nav-link.active {
  background-color: #F7F6F0;
  border: none;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.gallery-composition .active-card {
  background-color: #F7F6F0;
  border: none;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}

.wrapper-composition .nav-tabs .nav-link, .wrapper-composition .nav-tabs .nav-link:focus, .wrapper-composition .nav-tabs .nav-link:hover {
  border: none;
  padding: 1.8rem 0 .5rem 0

}

.wrapper-composition .nav-tabs {
  border-bottom: none
}

.wrapper-composition .nav-tabs .nav-item {
  margin-bottom: -2px
}

.wrapper-composition a, .wrapper-composition a:hover {
  color: #000
}

.card-product {
  box-shadow: 10px 13px 36px #00000017;
  width: 75%;
  padding: 100px 10px 10px 10px;
  background-color: #fff
}

.card-product img {
  left: 0;
  right: 0;
  bottom: 120%;
  top:0;
  width: 230px
}

.col-md-20 {
  -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.wrapper-title-home {
  top: 25%;
  left: 5%
}

.gallery-top-home h2{
  line-height: 60px
}

.logo-virgilio {
  height: auto;
}


.bg-home-carousel {
  height: 500px;
  background-position: center;
  background-size: cover
}

.wrapper-products-nav  .nav-tabs {
  border: none
}

.wrapper-products-nav .nav-tabs .nav-link.active {
  background-color: #FDFAF4;
  color: #063571;
  padding:  20px 40px;
  border: none
}


.wrapper-products-nav .nav-tabs .nav-link {
  background-color: #F0EFE8;
  color: #898D91;
  padding: 20px 40px;
  border: none
}


.dashboard-nav .nav-tabs .nav-link {
  color: #063571;
  border: none ;
  text-transform:uppercase;
  font-size: 20px;
  padding: 10px 50px;

}

.dashboard-nav .nav-tabs {
  border: none
}
.dashboard-nav .nav-tabs .nav-link.active {
  color: #063571;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 5px solid #063571
}

.order-card {
  min-height: 150px;
  margin: 0 auto 50px auto;
  box-shadow: 0px 3px 46px #00000012;
}

.round-number {
  width: 50px;
  height: 50px;
  border-radius: 50%
}

.round-number-path {
  border: 2px solid #063571
}

.btn-filter {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: fixed;
  bottom:5%;
  right:5%;
  z-index: 20
}

.filter-mobile {
  width: 90%;
  position: fixed;
  bottom:5%;
  right:5%;
  z-index: 20;
}

.filter-mobile li.active {
  background-color: #F0EFE8 !important
}
.filter-mobile li.active a {
  color: #898D91 !important;
  border: none
}


.filter-mobile--category li:first-child {
  border-top-left-radius: 10px;
}

.filter-mobile--category li:last-child {
  border-bottom-left-radius: 10px
}

.filter-mobile--subcategory {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

.border-bottom-right-radius {
  border-bottom-right-radius: 10px

}

.border-top-right-radius {
  border-top-right-radius: 10px
}


.wrapper-timeslot > .field > .required {
  margin-left: 15px !important;
}

.card-product h3 {
  min-height: 90px;
}
#searchForm #bitbag_elasticsearch_search_box {
  display: flex;
}

.card-product h5 {
  min-height: 18px;
}
