/*gallery page*/
.gallery-page  {
  width: 100%;
  height: 680px;
  position: relative;
  overflow: hidden;
}

.gallery-page.gallery-page-2  {
  width: 100%;
  height: auto;
}

.image-slider {
  height: 100%;
  width: 100%
}

.article-link {
  display: flex;
  position: relative;
}

.gallery-page .swiper-button-next::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  background-color: #063571;
  color: #fff
}
.gallery-page .swiper-button-prev::after {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  background-color: #063571;
  color: #fff
}

.gallery-page .swiper-button-prev,
.gallery-page .swiper-button-next {
  position: absolute;
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  height: 50px;
  width: 40px;
  color: #000000;
  background: transparent;
}
.gallery-page .swiper-button-prev::after,
.gallery-page .swiper-button-next::after {
  font-size: 20px;
}
.gallery-page .swiper-button-prev {
  right: auto;
  left: 0;
  bottom: auto;
  top: 50%;
}

/*.gallery-page .swiper-button-next.swiper-button-disabled,
.gallery-page .swiper-button-prev.swiper-button-disabled {
  opacity: 0
}*/


.gallery-page .swiper-button-next {
  right: 0;
  left: auto;
  bottom: auto;
  top: 50%;
}

.gallery-page .swiper-horizontal>.swiper-pagination-progressbar,
.gallery-page .swiper-pagination-progressbar.swiper-pagination-horizontal {
  bottom: 0;
  top: auto
}


.gallery-page .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #000
}

/**/

.gallery-composition .swiper-button-next::after {
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  color: #000;
  font-size: 40px
}
.gallery-composition .swiper-button-prev::after {
  content: "\f060";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  color: #000;
  font-size: 40px

}


.gallery-top-home .swiper-button-next::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  color: #063571;
  font-size: 40px
}

.gallery-top-home .swiper-button-prev::after {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 20px;
  color: #063571;
  font-size: 40px
}





.gallery-composition .swiper-button-prev,
.gallery-composition .swiper-button-next {
  position: absolute;
  bottom: 0;
  right: 1%;
  top: 10%;
  left: auto;
  height: 50px;
  width: 40px;
  color: #000000;
  background: transparent;
}
.gallery-composition .swiper-button-prev::after,
.gallery-composition .swiper-button-next::after {
  font-size: 20px;
}
.gallery-composition .swiper-button-prev {
  left: auto;
  right: 10%;
  bottom: 0;
  transition: all .5s ease;
}

.gallery-top-home .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1
}

.gallery-top-home .swiper-pagination-bullet-active {
  background-color: #063571
}


/**/

/*gallery product*/


.gallery-product {
  width: 90%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.gallery-product img{
  display: block;
  margin: 0 auto;
}


.gallery-product .swiper-button-next::after,
.gallery-home-products .swiper-button-next::after {
  content: "\e901";
  font-family: "icomoon";
  font-weight: 900;
  color: #fff;
  font-size: 40px !important
}

.gallery-product .swiper-button-prev::after,
.gallery-home-products .swiper-button-prev::after {
  content: "\e902";
  font-family: "icomoon";
  font-weight: 900;
  color: #fff;
  font-size: 40px !important
}

.gallery-products .swiper-button-prev::after {
  content: "\e902";
  font-family: "icomoon";
  font-weight: 900;
  color: #000;
  font-size: 25px !important
}

.gallery-products .swiper-button-next::after  {
  content: "\e901";
  font-family: "icomoon";
  font-weight: 900;
  color: #000;
  font-size: 25px !important
}


.gallery-products .swiper-button-prev,
.gallery-products .swiper-button-next {
  top: auto;
  bottom: 10%
}

.gallery-products .swiper-button-prev {
  left: auto;
  right: 20%
}


.gallery-home-products .swiper-button-next::after, .gallery-home-products .swiper-button-prev::after {
  color: #000
}


.gallery-product .swiper-button-prev,
.gallery-product .swiper-button-next {
  position: absolute;
  bottom: 0;
  right: 1%;
  top: 50%;
  left: auto;
  height: 50px;
  width: 40px;
  color: #000000;
  background: transparent;
}
.gallery-product .swiper-button-prev::after,
.gallery-product .swiper-button-next::after {
  font-size: 20px;
}
.gallery-product .swiper-button-prev {
  left: 0%;
  bottom: 0;
  transition: all .5s ease;
}


.gallery-product .swiper-pagination {
  text-align: left;
  margin-left: 20px
}

.gallery-product .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  background-color: transparent;
  opacity: 1;

}

.gallery-product .swiper-pagination-bullet-active {
  border: 1px solid #000;
  background-color: #000
}

.gallery-home-products .swiper-button-next {
  right: 4%
}

.gallery-home-products .swiper-button-prev {
  left: 4%
}

/* Gallery Generic */


.gallery-generic-home, .gallery-nav, .gallery-composition, .gallery-products, .gallery-top-home, .gallery-home-products {
  width: 100%;
  overflow: hidden;
}




.gallery-thumbs {
  order: 2;
  width: auto;
  height: auto;
  margin-right: 15px;
  overflow: hidden;
}
.gallery-thumbs .swiper-slide img {
  transition: 0.3s;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 0.9;
  background-color: #F7F6F0;
  padding: 5px 10px
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
