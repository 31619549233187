.color-one,  a.color-one, a.color-one:hover {
  color: #063571 !important;
  text-decoration: none
}

.color-two {
  color: #83827A
}

.color-three, a.color-two, a.color-two:hover {
  color: #7A99C1;
  text-decoration: none
}

.color-four, a.color-four, a.color-four:hover {
  color: #363636;
  text-decoration: none
}


.color-five, a.color-five, a.color-five:hover {
  color: #989898;
  text-decoration: none
}

.color-6, a.color-6, a.color-6:hover {
  color: #838383;
  text-decoration: none
}

.color-7, a.color-7, a.color-7:hover {
    color: #6EA3D1 !important;
    text-decoration: none
}

  
.text-white, a.text-white, a.text-white:hover {
  text-decoration: none
}

.text-black, a.text-black, a.text-black:hover {
  text-decoration: none;
  color: #000
}


.background-color-one {
  background-color: #fdfaf4
}

.background-color-two {
  background-color: #6EA3D1
}

.background-color-three {
  background-color: #F7F6F0
}

.background-color-four {
  background-color: #063571 !important
}

.background-color-five {
  background-color: #E4E9EF
}

.background-color-six {
  background-color: #E3EDF6
}


.background-color-seven {
  background-color: #EDEDED
}

.bg-color-8{
  background-color: #c8d9ee

}

.bg-color-9 {
  background-color: #F7F4ED

}
.bg-color-10 {
  background-color: #F0F0F0

}
.bg-color-11 {
  background-color: #F0EFE8

}

.bg-transparent {
  background: transparent
}
