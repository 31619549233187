label.error {
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  font-size: 14px;
  margin-top: 5px

}

.check-one input[type="radio"] {
  display: none;
}
.check-one input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #000;
}
.check-one input[type="radio"]:checked + * {
  color: #063571;
}
.check-one input[type="radio"]:checked + *::before {
  background: radial-gradient(#063571 0%, #063571 40%, transparent 50%, transparent);
  border-color: #063571;
  margin-bottom: 2px;
}


.check-one > input[type="radio"] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
}

/**/

.check-two {
  height: 23px;
  width: 23px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 2px solid #063571;
  border-radius: 3px;
  background: transparent;
  position: relative;
  margin-right: 10px;
  z-index: 2;
}

.check-two:checked {
  border: 2px solid #063571;
  background: #063571;
}

.check-two:checked:before, .check-two:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: #fff;
}

.check-two:checked:before {
  width: 8px;
  top: 11px;
  left: 2px;
  transform: rotate(44deg);
}

.check-two:checked:after {
  width: 14px;
  top: 8px;
  left: 5px;
  transform: rotate(-55deg);
}

.check-two:focus {
  outline: none;
}


.label-one {
  font-size: 16px;
  font-family:"Helvetica Bold";
}

.form-one select {
  border: none;
  border-bottom: 2px solid #000;
  color: #969696;
  padding-bottom: 5px;
  background-color: transparent;
  border-radius: 0;
  width: 100%;
  display: inline-block;
  webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-label {
  position: relative;
  width: 100%
}

.form-one select,
.form-one input[type='text'],
.form-one input[type='email'],
.form-one input[type='password']{
  border: none;
  border-bottom: 2px solid #063571;
  color: #063571;
  padding-bottom: 5px;
  background-color: transparent;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
  outline: none;
  display: inline-block;
  webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  padding: 0 0 10px 0 !important;
  margin-top: 10px;
}


.form-one textarea {
  border: 2px solid #063571;
  resize: none;
  height: 200px;
  width: 100%


}

.form-one input::placeholder {
  font-weight: 600;
  text-transform: uppercase;
  color: #063571
}

.select-label {
  position: relative;
  width: 100%
}



.form-one .select-label::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dd";
  font-size: 40px;
  position: absolute;
  display: inline-block;
  top: -10%;
  right: 3px;
  z-index: 1;
  pointer-events: none;
  color: #063571;

}

.form-one .select-label.select-label-1::after  {
  top: 30%;

}



/* radio button*/
.radio-one {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-one input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #063571
}

/* On mouse-over, add a grey background color */
.radio-one:hover input ~ .checkmark {
  background-color: transparent
}

/* When the radio button is checked, add a blue background */
.radio-one input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-one input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-one .checkmark:after {
 	top: 4px;
	left: 4px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #063571;
}
