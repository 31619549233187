.wrapper-cart-summary {
   max-height: 250px;
   border-bottom: 1px solid #000;
   overflow: auto;
 }

.wrapper-cart-products {
    /*max-height: 750px;
    overflow-x: hidden;
    overflow-y: auto;*/
  }


.bg-fast-cart {
  background: hsla(0, 0%, 0%, 0.5);
  position: fixed;
  z-index: 20
}

.wrapper-fast-cart {
  width: 550px;
  height: 100vh;
  background-color: #fff;
  top: 0;
  transform: translateX(800px);
  right: 0;
  position: fixed;
  z-index: 30;
  transition: all 0.50s ease-in-out;
  -webkit-transition: all 0.50s ease-in-out;
}

.wrapper-fast-cart.open {
  transform: translateX(0);
}

.fast-cart--products {
 overflow-x: hidden;
}

.fastcart--buttons {
  box-shadow: rgb(0 0 0 / 3%) 4px -10px 12px;

}

.fast-cart--header {
  padding: 10px 0
}

.fast-cart--body {
  height: auto;
  /*overflow-x: hidden;
  overflow-y: scroll;*/

}

.fast-cart--header h4{
  font-size: 22px;
  font-family: roboto,sans-serif;
}

.fast-cart-qta--control input {
  background-color: #ebf2f9;
  width: 50px;
  border: none;
  padding: 5px 0;
  color: #000
}

.fast-cart-qta--control span{
  background-color: #ebf2f9;
  padding: 0 10px;
  cursor: pointer;
  color: #000
}


.close-fast-cart {
  right: 10%
}

.fast-cart-list h5 {
  font-family: roboto,sans-serif;
}

.fast-cart-list {
  border-bottom: 2px solid #aabace
}


.sticky-review {
  position: sticky;
  top: 150px
}

/* width */
.fast-cart--products::-webkit-scrollbar {
  width: 10px;

}


.fast-cart--products::-webkit-scrollbar-track {
  background: #c8d9ee;
  border-radius: 50px

}

/* Handle */
.fast-cart--products::-webkit-scrollbar-thumb {
  background: #042f65;
  border-radius: 50px

}

/* Handle on hover */
.fast-cart--products::-webkit-scrollbar-thumb:hover {
  background: #042f65
}
